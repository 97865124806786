<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-row justify-content-end align-items-end">
      <div>
        <WButton info sm
          v-if="!isDisable && isGeneralSettingsChanged"
          @click.stop.prevent="cancelGeneralSettingsChanges"
          :disabled="isDisable"
        >
          {{ $t('SNMP.rollbackButton') }}
        </WButton>
      </div>
      <div>
        <WButton info sm
          customClass="ml-1"
          v-if="!isDisable"
          @click.stop.prevent="applyButtonHandler"
          :disabled="isDisable || !isGeneralSettingsChanged"
        >
          {{ $t('SNMP.apply') }}
        </WButton>
      </div>
    </div>
    <div class="d-flex w-100 flex-column justify-content-start align-items-start">
      <!--    system location-->
      <div class="form-group" v-if="showAllSettingsOnSNMPPartitionPages">
        <label for="snmp-general-system-location">{{ $t('SNMP.SNMPGeneral.systemLocation') }}:</label>
        <input
          :data-vv-as="$t('SNMP.SNMPGeneral.systemLocation')"
          type="text"
          :disabled="isDisable"
          v-model="systemLocation"
          name="snmp-general-system-location"
          id="snmp-general-system-location"
          :placeholder="$t('SNMP.SNMPGeneral.systemLocation')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-general-system-location') }"
        />
        <span v-show="errors.has('snmp-general-system-location')" class="help is-danger">
          {{ errors.first('snmp-general-system-location') }}
        </span>
      </div>
      <!--    system contact -->
      <div class="form-group" v-if="showAllSettingsOnSNMPPartitionPages">
        <label for="snmp-general-system-contact">{{ $t('SNMP.SNMPGeneral.systemContact') }}:</label>
        <input
          :data-vv-as="$t('SNMP.SNMPGeneral.systemContact')"
          type="text"
          :disabled="isDisable"
          v-model="systemContact"
          name="snmp-general-system-contact"
          id="snmp-general-system-contact"
          :placeholder="$t('SNMP.SNMPGeneral.systemContact')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-general-system-contact') }"
        />
        <span v-show="errors.has('snmp-general-system-contact')" class="help is-danger">
          {{ errors.first('snmp-general-system-contact') }}
        </span>
      </div>
      <!--    snmp traps -->
      <div class="form-group">
        <label for="snmp-general-system-contact">{{ $t('SNMP.SNMPGeneral.snmpTraps') }}:</label>
      </div>
      <div class="w-100">
        <SNMPTrapsSelector
          :s-n-m-p-traps-available-prop="SNMPTrapsAvailable"
          :s-n-m-p-traps-enabled-prop="SNMPTrapsEnabled"
          @SNMPTrapsAvailableOrEnabledChangedEmit="SNMPTrapsAvailableOrEnabledChangedEmitHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SNMPTrapsSelector from '../../../../components/Administration/SNMP/SNMPTrapsSelector.vue';
import snmpGeneralService from '../../../../services/Administration/SNMP/snmpGeneralService';

export default {
  name: 'SNMPGeneral',
  components: { SNMPTrapsSelector },
  data() {
    return {
      generalSNMPSettigns: [],
      systemLocation: '',
      systemContact: '',
      SNMPTrapsAvailable: [],
      SNMPTrapsEnabled: [],
      systemLocationForCompare: '',
      systemContactForCompare: '',
      SNMPTrapsAvailableForCompare: [],
      SNMPTrapsEnabledForCompare: []
    };
  },
  methods: {
    cancelGeneralSettingsChanges(){
      this.SNMPTrapsAvailable = JSON.parse(JSON.stringify(this.SNMPTrapsAvailableForCompare));
      this.SNMPTrapsEnabled = JSON.parse(JSON.stringify(this.SNMPTrapsEnabledForCompare));
      this.systemLocation = this.systemLocationForCompare;
      this.systemContact = this.systemContactForCompare;
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    applyButtonHandler() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.generalSNMPSettigns.traps.available = this.SNMPTrapsAvailable;
            this.generalSNMPSettigns.traps.enabled = this.SNMPTrapsEnabled;
            this.generalSNMPSettigns.system_location = this.systemLocation;
            this.generalSNMPSettigns.system_contacts = this.systemContact;
            snmpGeneralService.updateForGeneral(this);
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    SNMPTrapsAvailableOrEnabledChangedEmitHandler(payload) {
      const available = payload.available;
      const enabled = payload.enabled;
      this.SNMPTrapsAvailable = available;
      this.SNMPTrapsEnabled = enabled;
    },
    getAll() {
      snmpGeneralService.getAllForGeneral(this);
    }
  },
  created() {
    this.getAll()
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    isGeneralSettingsChanged() {
      return this.isSNMPTrapsChanged || this.isSystemFieldsChanged
    },
    isSNMPTrapsChanged() {
      const SNMPTrapsAvailable = this.SNMPTrapsAvailable
      const SNMPTrapsEnabled = this.SNMPTrapsEnabled
      const SNMPTrapsAvailableForCompare = this.SNMPTrapsAvailableForCompare
      const SNMPTrapsEnabledForCompare = this.SNMPTrapsEnabledForCompare

      return (JSON.stringify(SNMPTrapsAvailable) !== JSON.stringify(SNMPTrapsAvailableForCompare)) ||
        (JSON.stringify(SNMPTrapsEnabled) !== JSON.stringify(SNMPTrapsEnabledForCompare));
    },
    isSystemFieldsChanged() {
      const systemLocation = this.systemLocation;
      const systemContact = this.systemContact;
      const systemLocationForCompare = this.systemLocationForCompare;
      const systemContactForCompare = this.systemContactForCompare
      return (systemLocation !== systemLocationForCompare) || (systemContact !== systemContactForCompare)

    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPGeneralForGeneralRequestExecuting
    }
  },
};
</script>

<style scoped></style>
