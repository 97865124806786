var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"w-100 d-flex flex-wrap justify-content-around align-items-center"},[_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('div',{staticClass:"h6"},[_vm._v(_vm._s(_vm.$t('SNMP.available'))+" ("+_vm._s(_vm.availableCounter)+")")]),_c('div',{staticClass:"snmp-traps__list"},[_vm._l((_vm.SNMPTrapsAvailableSortedByName),function(avalable){return _c('div',{key:avalable,staticClass:"snmp-traps_list-item d-flex flex-row justify-content-between"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: avalable,
              popperOptions: {
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }
            }),expression:"{\n              content: avalable,\n              popperOptions: {\n                modifiers: {\n                  preventOverflow: {\n                    boundariesElement: 'window'\n                  }\n                }\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"snmp-traps_list-item-name"},[_vm._v(" "+_vm._s(avalable)+" ")]),(!_vm.isDisable)?_c('div',{staticClass:"float-right",attrs:{"role":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.addAvailableClickHandler(avalable)}}},[_c('i',{staticClass:"icon-arrow-right btn-outline-info"})]):_vm._e()])}),(_vm.SNMPTrapsAvailable.length === 0)?_c('div',{staticClass:"snmp-traps_list-item-no-data d-flex flex-column justify-content-center align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('SNMP.noOptions'))+" ")])]):_vm._e()],2),_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('WButton',{attrs:{"info":"","sm":"","disabled":_vm.isDisable || _vm.availableCounter === 0},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.enableAllClickHandler($event)}}},[_vm._v(" "+_vm._s(_vm.$t('SNMP.selectAll'))+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('div',{staticClass:"h6"},[_vm._v(_vm._s(_vm.$t('SNMP.enabled'))+" ("+_vm._s(_vm.enabledCounter)+")")]),_c('div',{staticClass:"snmp-traps__list"},[_vm._l((_vm.SNMPTrapsEnabledSortedByName),function(enabled){return _c('div',{key:enabled,staticClass:"snmp-traps_list-item d-flex flex-row justify-content-between"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: enabled,
              popperOptions: {
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }
            }),expression:"{\n              content: enabled,\n              popperOptions: {\n                modifiers: {\n                  preventOverflow: {\n                    boundariesElement: 'window'\n                  }\n                }\n              }\n            }",modifiers:{"top-center":true}}],staticClass:"snmp-traps_list-item-name"},[_vm._v(" "+_vm._s(enabled)+" ")]),(!_vm.isDisable)?_c('div',{staticClass:"float-left",attrs:{"role":"button","disabled":_vm.isDisable},on:{"click":function($event){return _vm.removeEnabledClickHandler(enabled)}}},[_c('i',{staticClass:"icon-arrow-left btn-outline-info"})]):_vm._e()])}),(_vm.SNMPTrapsEnabled.length === 0)?_c('div',{staticClass:"snmp-traps_list-item-no-data d-flex flex-column justify-content-center align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('SNMP.SNMPGeneral.snmpTrapsNoEnabled'))+" ")])]):_vm._e()],2),_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('WButton',{attrs:{"info":"","sm":"","disabled":_vm.isDisable || _vm.enabledCounter === 0},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.disableAllClickHandler($event)}}},[_vm._v(" "+_vm._s(_vm.$t('SNMP.disableAll'))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }