<template>
  <div class="w-100">
    <div class="w-100 d-flex flex-wrap justify-content-around align-items-center">
      <div class="d-flex flex-column justify-content-start align-items-start">
        <div class="h6">{{ $t('SNMP.available') }}&nbsp;({{ availableCounter }})</div>
        <div class="snmp-traps__list">
          <div
            v-for="avalable of SNMPTrapsAvailableSortedByName"
            :key="avalable"
            class="snmp-traps_list-item d-flex flex-row justify-content-between"
          >
            <div
              v-tooltip.top-center="{
                content: avalable,
                popperOptions: {
                  modifiers: {
                    preventOverflow: {
                      boundariesElement: 'window'
                    }
                  }
                }
              }"
              class="snmp-traps_list-item-name"
            >
              {{ avalable }}
            </div>
            <div role="button" :disabled="isDisable" v-if="!isDisable" @click="addAvailableClickHandler(avalable)" class="float-right">
              <i class="icon-arrow-right btn-outline-info"></i>
            </div>
          </div>
          <div
            class="snmp-traps_list-item-no-data d-flex flex-column justify-content-center align-items-center"
            v-if="SNMPTrapsAvailable.length === 0"
          >
            <div>
              {{ $t('SNMP.noOptions') }}
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <WButton info sm
            @click.prevent.stop="enableAllClickHandler"
            :disabled="isDisable || availableCounter === 0"
          >
            {{ $t('SNMP.selectAll') }}
          </WButton>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-start align-items-start">
        <div class="h6">{{ $t('SNMP.enabled') }}&nbsp;({{ enabledCounter }})</div>
        <div class="snmp-traps__list">
          <div
            v-for="enabled of SNMPTrapsEnabledSortedByName"
            :key="enabled"
            class="snmp-traps_list-item d-flex flex-row justify-content-between"
          >
            <div
              v-tooltip.top-center="{
                content: enabled,
                popperOptions: {
                  modifiers: {
                    preventOverflow: {
                      boundariesElement: 'window'
                    }
                  }
                }
              }"
              class="snmp-traps_list-item-name"
            >
              {{ enabled }}
            </div>
            <div role="button" :disabled="isDisable" v-if="!isDisable" @click="removeEnabledClickHandler(enabled)" class="float-left">
              <i class="icon-arrow-left btn-outline-info"></i>
            </div>
          </div>
          <div
            class="snmp-traps_list-item-no-data d-flex flex-column justify-content-center align-items-center"
            v-if="SNMPTrapsEnabled.length === 0"
          >
            <div>
              {{ $t('SNMP.SNMPGeneral.snmpTrapsNoEnabled') }}
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <WButton info sm
            @click.prevent.stop="disableAllClickHandler"
            :disabled="isDisable || enabledCounter === 0"
          >
            {{ $t('SNMP.disableAll') }}
          </WButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SNMPTrapsSelector',
  data() {
    return {
      SNMPTrapsEnabled: JSON.parse(JSON.stringify(this.SNMPTrapsEnabledProp)),
      SNMPTrapsAvailable: JSON.parse(JSON.stringify(this.SNMPTrapsAvailableProp))
    };
  },
  props: {
    SNMPTrapsAvailableProp: {
      type: Array,
      required: true
    },
    SNMPTrapsEnabledProp: {
      type: Array,
      required: true
    }
  },
  computed: {
    SNMPTrapsEnabledSortedByName() {
      try {
        const SNMPTrapsEnabled = this.SNMPTrapsEnabled
        return JSON.parse(JSON.stringify(SNMPTrapsEnabled)).sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });

      } catch (e) {
        return this.SNMPTrapsEnabled;
      }
    },
    SNMPTrapsAvailableSortedByName() {
      try {
        const SNMPTrapsAvailable = this.SNMPTrapsAvailable;
        return JSON.parse(JSON.stringify(SNMPTrapsAvailable)).sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });

      } catch (e) {
        return this.SNMPTrapsAvailable;
      }
    },
    availableCounter() {
      return this.SNMPTrapsAvailable.length;
    },
    enabledCounter() {
      return this.SNMPTrapsEnabled.length;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    }
  },
  methods: {
    SNMPTrapsEnabledChangedHandler() {
      this.SNMPTrapsAvailableOrEnabledChangedEmit();
    },
    SNMPTrapsAvailableOrEnabledChangedEmit() {
      this.$emit('SNMPTrapsAvailableOrEnabledChangedEmit',
        {
          enabled: JSON.parse(JSON.stringify(this.SNMPTrapsEnabled)),
          available: JSON.parse(JSON.stringify(this.SNMPTrapsAvailable))
        });
    },
    enableAllClickHandler() {
      if (this.isDisabled) {
        return;
      }
      // console.log('enableAllClick Handled');
      this.SNMPTrapsAvailable.forEach((item) => {
        if (!this.SNMPTrapsEnabled.includes(item)) {
          this.SNMPTrapsEnabled.push(item);
        }
      });
      this.SNMPTrapsAvailable = [];
      this.SNMPTrapsAvailableOrEnabledChangedEmit();
    },
    disableAllClickHandler() {
      if (this.isDisabled) {
        return;
      }
      // console.log('disableAllClickHandlerAllClick Handled');
      this.SNMPTrapsEnabled.forEach((item) => {
        if (!this.SNMPTrapsAvailable.includes(item)) {
          this.SNMPTrapsAvailable.push(item);
        }
      });
      this.SNMPTrapsEnabled = [];
      this.SNMPTrapsAvailableOrEnabledChangedEmit();
    },
    // addAvailableClickHandler(item) {
    //   if (this.isDisabled) {
    //     return;
    //   }
    //   if (!this.SNMPTrapsEnabled.includes(item)) {
    //     this.SNMPTrapsEnabled.push(item);
    //     this.SNMPTrapsAvailableOrEnabledChangedEmit();
    //   }
    // },
    addAvailableClickHandler(item) {
      if (this.isDisabled) {
        return;
      }

      this.SNMPTrapsEnabled.push(item);
      const arrIndex = this.SNMPTrapsAvailable.indexOf(item);
      const arrForCut = JSON.parse(JSON.stringify(this.SNMPTrapsAvailable));
      arrForCut.splice(arrIndex, 1);
      this.SNMPTrapsAvailable = arrForCut;
      this.SNMPTrapsAvailableOrEnabledChangedEmit();

    },
    removeEnabledClickHandler(item) {
      if (this.isDisabled) {
        return;
      }
      if (!this.SNMPTrapsEnabled.includes(item)) {
        return;
      } else {
        console.log(item)
        const arrIndex = this.SNMPTrapsEnabled.indexOf(item);
        const arrForCut = JSON.parse(JSON.stringify(this.SNMPTrapsEnabled));
        arrForCut.splice(arrIndex, 1);
        this.SNMPTrapsEnabled = arrForCut;
        this.$set(this.SNMPTrapsAvailable, this.SNMPTrapsAvailable.length, item)
        this.SNMPTrapsAvailableOrEnabledChangedEmit();
      }
    }
  },
  watch: {
    SNMPTrapsEnabledProp: {
      deep: true,
      handler() {
        this.SNMPTrapsEnabled = JSON.parse(JSON.stringify(this.SNMPTrapsEnabledProp))
      }
    },
    SNMPTrapsAvailableProp: {
      deep: true,
      handler() {
        this.SNMPTrapsAvailable = JSON.parse(JSON.stringify(this.SNMPTrapsAvailableProp))
      }
    }
  }
};
</script>

<style scoped>
.snmp-traps__list {
  min-height: 250px;
  max-height: 250px;
  min-width: 350px;
  width: 479px;
  height: 250px;
  border: 1px dotted lightgray;
  margin-top: 1em;
  margin-bottom: 1em;
  overflow-y: scroll;
  /*background: lightgray;*/
}
.snmp-traps_list-item {
  min-width: 300px;
  border: 1px solid lightgray;
  margin: 0.5em;
  padding: 0.25em;
  box-shadow: 1px 1px 1px lightgray;
  /*background: white;*/
}
.snmp-traps_list-item-no-data {
  min-height: 250px;
  min-width: 300px;
  /*background: white;*/
}
.snmp-traps_list-item-name {
  /*max-width: 260px;*/
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>
